<template>
  <div class="h-100">
    <RoamView />
  </div>
</template>

<script>
import RoamView from "@/views/config/dataManage/roam/roamView.vue"
export default {
  components: { RoamView }
}
</script>

<style>

</style>